// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-shop-js": () => import("/opt/build/repo/src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-product-js": () => import("/opt/build/repo/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-portfolio-js": () => import("/opt/build/repo/src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

